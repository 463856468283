<template>
  <div class="space-x-2 items-center inline-flex text-sm p-1 px-2 rounded-2xl bg-[#D3ECEF] text-teal uppercase">
    <span class="font-medium">
      Guest <span data-testid="guestCount">{{ guestCountAsWord }}</span>
    </span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  guestCount?: number
}>()

const { guestCount } = toRefs(props)

const guestCountAsWord = computed(() => {
  switch (guestCount.value) {
    case 1: return 'One'
    case 2: return 'Two'
    case 3: return 'Three'
    case 4: return 'Four'
    case 5: return 'Five'
    default: return ''
  }
})
</script>
